@import "../../../styles/global/style.scss";

.organization-form {
    .ant-form-item {
      margin-bottom: 16px;
  
      .ant-form-item-label {
        label {
          font-weight: 500;
          font-size: 14px;
          color: #333;
        }
      }
  
      .ant-form-item-control-input {
        input {
          padding: 8px 12px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          transition: all 0.3s;
  
          &:focus {
            border-color: #40a9ff;
            box-shadow: 0 0 5px rgba(64, 169, 255, 0.2);
          }
        }
      }
    }
  
    .form-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
  
      button {
        &:first-child {
          margin-right: 8px;
        }
  
        &.cancel-button {
          background-color: #f5f5f5;
          border-color: #d9d9d9;
          color: #333;
  
          &:hover {
            background-color: #e6e6e6;
            border-color: #bfbfbf;
          }
        }
  
        &.submit-button {
          background-color: #1890ff;
          border-color: #1890ff;
          color: white;
  
          &:hover {
            background-color: #40a9ff;
            border-color: #40a9ff;
          }
        }
      }
    }

    .radio-text {
      color: var(--text-color) !important;
      margin-bottom: 12px;
    }
}  

.ant-form-item-required {
  color: var(--text-color) !important;
}