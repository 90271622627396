@import '../../styles/global/style.scss';

.dashboard {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-x: hidden;


    .main-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .card-parent {
            padding: 20px;
            display: flex;
            flex-wrap: wrap; 
            gap: 20px;
            justify-content: space-between; 
            max-width: 1200px; 

            .card {
                flex: 1 1 calc(25% - 20px); 
                max-width: calc(25% - 20px); 
            }
        }
    }
}

// Media queries for responsiveness

@media (max-width: 1024px) {
    .dashboard {
        .main-content {
            .card-parent {
                .card {
                    flex: 1 1 calc(33.33% - 20px); // 3 cards in a row on medium screens
                    max-width: calc(33.33% - 20px);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .dashboard {
        .main-content {
            .card-parent {
                .card {
                    flex: 1 1 calc(50% - 20px); // 2 cards in a row on tablets
                    max-width: calc(50% - 20px);
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .dashboard {
        .main-content {
            .card-parent {
                .card {
                    flex: 1 1 calc(100% - 20px); // 1 card per row on mobile screens
                    max-width: calc(100% - 20px);
                }
            }
        }
    }
}

.collapsed {
    width: calc(100dvw - 100px);
}

.open {
    width: 80dvw;
}