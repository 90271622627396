@import '../../styles/global/style.scss';

.test-instruction {
    width: 100dvw;

    .header {
        width: 100dvw;
        height: auto;
    }

    .body {
        padding: 20px;
        display: flex;
        gap: 16px;
        
        .body-left {
            width: 60dvw;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .body-right {
            width: calc(40dvw - 20px);
        }
    }
}

@media (max-width: 810px) {
    .body {
        flex-direction: column;
    }

    .body-left {
        width: calc(100dvw - 32px) !important;
    }

    .body-right {
        width: calc(100dvw - 32px) !important;
    }
}

.jab {
    background-color: black !important;
}