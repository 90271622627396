@import "../../../styles/global/style.scss";

.test-form {
  .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label {
      label {
        font-weight: 500;
        font-size: 14px;
        color: #333;
      }
    }

    .ant-form-item-control-input {
      input {
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        transition: all 0.3s;

        &:focus {
          border-color: #40a9ff;
          box-shadow: 0 0 5px rgba(64, 169, 255, 0.2);
        }
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;

    button {
      &.cancel-button {
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        color: #333;

        &:hover {
          background-color: #e6e6e6;
          border-color: #bfbfbf;
        }
      }

      &.submit-button {
        background-color: #1890ff;
        border-color: #1890ff;
        color: white;

        &:hover {
          background-color: #40a9ff;
          border-color: #40a9ff;
        }
      }
    }
  }

  .radio-text {
    color: var(--text-color) !important;
    margin-bottom: 12px;
  }

  .ques-section-parent {
    display: flex;
    height: 60dvh;
    gap: 8px;
  
    .outline {
      height: 55dvh;
      border: 1px solid #bfbfbf;
      width: 50%;
      border-radius: 8px;
    }

    .section {
      padding: 20px;
      overflow: scroll;
      
      .section-header {
        display: flex;
        justify-content: end;
        margin-bottom: 20px;

        .add-section-button {
          max-width: 300px;
        }
      }

      .list-item {
        display: flex;

        .list-item-input {
          width: 100px;
          height: 30px !important;
        }

        .custom-width {
          max-width: 300px;
        }

        .list-action {
          display: flex;
          gap: 8px;
        }
      }
      
    }

    .question {

      .add-width {
        max-width: 300px !important;
      }

      .question-list {
        overflow: scroll;
        padding: 12px;
      }

      .question-footer {
        display: flex;
        justify-content: end;
        gap: 8px;

        .select {
          width: 200px;
        }
        .add-button{
          height: 40px;
        }
      }

      .question-header {
        display: flex;
        justify-content: space-between;
        gap: 8px; 
      }  
      
      .search {
        max-width: 200px;
      }
    }
  }
  .custom-text-color {
    color: var(--text-color);
  } 

  .custom-cursor {
    cursor: pointer;
  }
}

.ant-form-item-required {
  color: var(--text-color) !important;
}

.ant-input-search-button {
  height: 38px !important;
  border-radius: 0px 6px 6px 0px;
}

.add-section-button-placeholder-affix-wrapper {
  height: 40px !important;
}

.add-section-button-placeholder {
  font-size: 14px !important;
}

.add-section-button-placeholder-group-addon {
  padding: 0 !important;
  height: 34px !important;
  border: 1px solid $color-primary !important;
}

.ant-empty {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-header-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-icon {
  margin-left: 8px;
  color: red;
  cursor: pointer;
}

.ant-collapse-expand-icon {
  color: var(--text-color) !important;
}

.ant-collapse-header-text {
  color: var(--text-color) !important;
}

@media (max-width: 930px) {
  .ques-section-parent {
    flex-direction: column;
    overflow: scroll;
    height: 100dvh !important;
  }

  .question{
    width: 100% !important;
  }

  .section {
    width: 100% !important;
  }
}

.next-button {
  display: flex;
  justify-content: end;
  align-items: end;
}

.candidate-parent {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .candidate-heading {
    font-size: '14px';
    font-weight: 500;
    color: var(--text-color);
  }

  .checkbox-group-candidate {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 10px;
    height: 150px;
    overflow-y: scroll;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  
  .ant-checkbox {
    margin: 0;
  }

  .candidate-empty-box {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    margin-inline: 0px !important;
    padding: 10px;
    margin-bottom: 16px;
  }
}

