@import '../../styles/global/style.scss';

.login-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100dvh;
  background-color: var(--background-color);

  .login-left {
    width: 35%;
    background-color: var(--primary-color);
    padding: 2rem;
    box-shadow: 0 0 10px var(--box-shadow-color);
    height: 100dvh;

    .login-left-main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 80dvh;
      padding: 0rem 5rem 5rem 5rem;

      .validation-message {
        color: red;
        font-size: 0.9rem;
        margin-top: 0.5rem;
      }

      p {
        margin-bottom: 2rem;
        color: var(--text-color);
      }

      .login-input {
        margin-bottom: 1rem;
        width: 100%;
      }

      .login-button {
        width: 100%;
        margin-bottom: 1rem;
      }

      .resend-button:disabled { 
        color: var(--text-color);
      }

      .or-divider {
        display: flex;
        align-items: center;

        .or {
          text-align: center;
          margin: 0 1rem;
          color: var(--text-color);
        }

        .divider {
          flex-grow: 1;
          height: 1px;
          background-color: var(--secondary-color);
        }
      }

      .google-login {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem;
        // color: var(--text-color);
      }

      .google-login:disabled {
        color: var(--text-color);
      }

      .google-icon {
        height: 21px;
        width: 21px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    .login-left-header {
      display: flex;
      justify-content: space-between;

      .main-logo {
        height: 85px;
        width: 120px;
      }
      .switch {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
      }
    }

    .login-left-footer {
      display: flex;
      gap: 10px; 
      justify-content: center;
    }
    
    .toggle-button {
      width: 80px;
      height: 8px;  
      background-color: #e0e0e0;
      border-radius: 15px; 
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.3s;
    
      &.active {
        background-color: $color-primary; 
        color: #fff;
        border-color: $color-primary;
      }
    }
    
  }

  .login-right {
    width: 60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: space-between;

    h3 {
      margin-bottom: 1rem;
      color: var(--text-color);
    }

    .login-image {
      height: 75dvh;
      width: 60dvw;
      border-top-left-radius: 26px;
    }

    .login-right-top {
      padding: 5rem 0rem 0rem 0rem;
      height: 25dvh;

      p {
        max-width: 90%;
        color: var(--text-color);
      }

      .about {
        display: flex;
        gap: 2px;

        .dot-image-parent {
          display: flex;
          justify-content: center;
          align-items: center;

          .dot-image {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .login-container {
    flex-direction: column;
    
    .login-left {
      width: 100%;
      padding: 1rem;
    }

    .login-right {
      display: none;
    }
  }
}