@import '../../styles/global/style.scss';

.tag {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-x: hidden;
  gap: 20px;

  .head {
    display: flex;
    justify-content: space-between;

    .button {
      height: 40px;
      width: 150px;
    }

    .head-right {
      display: flex;
      gap: 10px;
    }
  }

  .table {
    .ant-pagination-item-link {
      color: var(--text-color);
    }
  }
}

.collapsed {
  width: calc(100dvw - 100px);
}

.open {
  width: 80dvw;
}

.unauthorized {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-x: hidden;
  gap: 20px;
}

.tag-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--secondary-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow-color);

  p{
    color: var(--text-color) ;
  }

  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 24px;
      color: var(--primary-color);
    }

    .back-button {
      button {
        background-color: var(--secondary-color);
        color: var(--text-color);
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          background-color: var(--secondary-hover-color);
        }
      }
    }
  }

  .detail-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .info {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid var(--border-color);

      .label {
        font-weight: bold;
        color: var(--text-dark-color);
      }

      .value {
        color: var(--text-color);
      }
    }
  }

}


@media (max-width: 850px) {
  .head {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 650px) {
  .head-right {
    flex-direction: column;
  }
}