.light {
    --background-color: #f5f5f5;
    --text-color: #000;
    --primary-color: #fff;
    --secondary-color: #eeeeeec8;
    --box-shadow-color: rgba(0, 0, 0, 0.1);
    --modal-background-color: #eeeeeee1;
  }
  
  .dark {
    --background-color: #1e1e1e;
    --text-color: #fff;
    --primary-color: #2c2c2c;
    --secondary-color: #4e4e4e;
    --box-shadow-color: rgba(255, 255, 255, 0.1);
    --modal-background-color: #4e4e4ef0;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  