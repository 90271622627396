.setting {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-x: hidden;

    
}

.collapsed {
    width: calc(100dvw - 100px);
}

.open {
    width: 80dvw;
}