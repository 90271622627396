.theme {
    display: flex;
    gap: 4px;
    width: 100px;
    background-color: #edecec;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;

    .inner-radio {
       width: 50px;
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 8px;
       border-radius: 50%;
       
        .radio-icon {
            height: 20px;
            width: 20px;
        }
    }

    .light {
            background-color: white;
    }

    .dark {
            background-color: black;
    }
}