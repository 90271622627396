@import "../../styles/global/style.scss";

.primary-sidebar {
  height: 100dvh;
  width: 20dvw;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s;
  padding: 20px;

  &.collapsed {
    width: 100px;
  }

  .profile-section {
    display: flex;
    padding: 20px 4px 16px 4px;
    gap: 8px;
    height: 12dvh;
    width: 100%;

    .profile-picture {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transition: margin-bottom 0.3s;
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: var(--text-color);
      opacity: 1;
      transition: opacity 0.3s;
      height: 100%;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }

      div {
        margin: 2px 0;
        font-size: 14px;
      }
      
      .level-badge {
        padding: 5px 15px;
        border: 1px solid black;
        border-radius: 20px;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .ant-menu {
    border-right: none;
    width: 100%;
    height: 78dvh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    background-color: var(--primary-color);

    .ant-menu-item {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      height: 50px;

      &:hover {
        color: #fff;
      }
  
      &.collapsed {
        justify-content: center;
        padding-left: 0;
      }

      .menu-item-text {
        display: inline-block;
        transition: opacity 0.3s;
      }
    }

    .ant-menu-item-selected {
      background-color: $color-primary;
      color: #fff;
      border-radius: 12px;
    }

    .ant-menu-item-icon {
      font-size: 18px;
    }
  }

  .footer-sidebar {
    display: flex;
    transition: color 0.3s;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 10dvh;

    .logout-button {
      padding: 5px;
      color: var(--text-color);
      font-size: 24px;
      display: flex;
    }

    .text {
      font-size: 16px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
