@import '../../../styles/global/style.scss';

.test-instruction-card {
    border: 1px solid rgb(208, 208, 208);
    border-radius: 8px;
    background-color: var(--primary-color);

    .card-header {
        padding: 20px;
        height: 70px;
        border-radius: 7px;
        background-color: #f5f5f5;
        h2 {
            margin: 0;
            font-size: 1.5em;
            color: #333;
        }
    }

    .instruction-section {
        padding: 20px;

        .instruction {
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;

            .number {
                min-width: 30px;
                min-height: 30px;
                background-color: #d0d0d0;
                color: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                margin-right: 10px;

                &:nth-child(1) {
                    background-color: #1e90ff;
                }
                &:nth-child(2) {
                    background-color: #98c379;
                }
            }

            p {
                margin: 0;
                color: var(--text-color);
                font-size: 1em;
                line-height: 1.5;

                .highlight {
                    display: inline-block;
                    background-color: #8bc34a;
                    color: #fff;
                    padding: 0 5px;
                    border-radius: 3px;
                    font-weight: bold;
                    margin-left: 5px;
                }

                strong {
                    font-weight: bold;
                }
            }
        }
    }
}
