@import '../../../../styles/global/style.scss';

.modal-width-height {
    width: 80dvw !important;
}

.instruction-Modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    max-height: 65dvh !important;
    overflow-y: scroll;
}