// @import '~antd/dist/antd.css';
@import './base';
@import './varaibles';
@import './typography';
@import './themes';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-modal-content {
  background-color: var(--primary-color) !important;
}

.ant-modal-header {
  background: var(--primary-color) !important;
}

.ant-modal-close-icon {
  color: var(--text-color) !important;
}

.ant-modal-title {
  color: var(--text-color) !important;
}

.ant-table {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;

  &:hover {
      background-color: var(--primary-color) !important;
  }
}

.ant-table-cell-row-hover {
      background-color: var(--primary-color) !important;
}

.ant-modal-confirm-title {
  color: var(--text-color) !important;
}

.antd-search-input-custom {
  height: 40px;
  max-width: 200px;
}

.ant-select {
  min-height: 40px !important;
}

.ant-select-selector {
  min-height: 40px !important;
  background-color: white !important;
}

.ant-modal-wrap {
  background-color: var(--modal-background-color) !important;
}

.ant-segmented {
  height: 40px !important;
}

.ant-segmented-item-label {
  height: 36px !important;
}

.ant-segmented-item-label {
  justify-content: center;
  display: flex;
  align-items: center;
}

.center {
  justify-content: center;
  display: flex;
  align-items: center;
}

.ant-segmented-item-selected {
  background-color: $color-primary !important;
  color: white !important;
}

.ant-segmented-thumb {
  background-color: $color-primary !important;
  color: white !important;
}

.ant-table-row {
  background-color: var(--primary-color);
}

.ant-upload-list-item-name {
  color: var(--text-color) !important;
}

.quill {
  background-color: white !important;
}

.ant-input {
  background-color: white !important;
  height: 40px !important;
}

.ant-picker {
  height: 40px !important;
}

.table-outer-spinner {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-section {
  min-width: 150px;
}

.ant-pagination-item-link {
  color: var(--text-color) !important;
}

.ant-empty-description {
  color: var(--text-color) !important;
}

.global-text-color {
  color: var(--text-color) !important;
}