.thankyou-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f6f9fc;
  
    .ant-result {
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
  
      .ant-result-title {
        font-size: 24px;
        font-weight: 600;
        color: #0b5ed7;
      }
  
      .ant-result-subtitle {
        font-size: 16px;
        color: #6c757d;
      }
  
      .ant-btn-primary {
        background-color: #0b5ed7;
        border-color: #0b5ed7;
  
        &:hover {
          background-color: #084298;
          border-color: #084298;
        }
      }
    }
  }  