@import '../../../styles/global/style.scss';

.introduction-card {
        border: 1px solid rgb(208, 208, 208);
        border-radius: 8px;

    .card-header {
        padding: 20px;
        height: 70px;
        background-color: white;
        border-radius: 7px;
    }

    .heading {
        padding: 20px;
        display: flex;
        gap: 10px;
        flex-direction: column;

       .semi-intro {
         background-color: var(--primary-color);
         color: var(--text-color);
         padding: 20px;
         border-radius: 8px;
       }
    }

    .test-summary {

     .summary-list {   
        padding: 20px;
        
        .total-time {
            margin-top: 10px;
            display: flex;
            justify-content: end;

            .time-limit {
                color:#7e7e7e;
            }
        }

        .summary-header {
            display: flex;
            gap: 2%;

            .exam-part {
                padding-left: 20px;
            }
            
            .column {
                width: 49%;
                color: #7e7e7e;
            }
        }

        .summary-item {
            background-color: var(--primary-color);
            border-radius: 4px;
            padding: 20px;
            display: flex;
            margin-top: 10px;

            .column {
                width: 50%;
            }
        }
     }   
    }

    @media (max-width: 400px) {
        .invisible {
            display: none;
        }
    }
}