@import '../../styles/global/style.scss';

.header {
    height: 80px;
    display: flex;
    justify-content: space-between;

    .header-left {
        padding: 20px 40px;

        .sub-heading {
            color: $color-font-dull;
            font-size: small;
        }
    }

    .header-right {
        padding: 20px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .header {
        flex-direction: column;
        height: 200px;
        justify-content: space-evenly;
    }

    .header-right {
        padding: 0px 20px !important;
        justify-content: start !important;
    }

    .header-left {
        padding: 0px 20px !important;
    }

}

.width {
    width: 80dvw;
}

.width-collapsed {
    width: calc(100dvw - 100px);
}