@import '../../styles/global/style.scss';

.test-card {
    border-radius: 12px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .test-type-tag {
    font-size: 12px;
  }
  
  .text-strong {
    font-size: 14px;
    color: #595959;
  }
  
  .highlight-text {
    color: #2a9d8f;
  }
  
  .icon {
    color: #ff5722;
    margin-right: 4px;
  }
  
  .button-container {
    text-align: center;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
  
  .start-button {
    background-color: #2a9d8f;
    border-color: #2a9d8f;
    min-width: 130px;
  }
  
  .disabled-button {
    color: #595959;
    min-width: 130px;
  }  

  .instruction-button {
    min-width: 130px;
  }

  @media (max-width: 460px) {
    .button-container {
      flex-direction: column !important;
    }

    .card-title {
      flex-direction: column !important;
      margin: 12px;
    }

  }