@import '../../../styles/global/style.scss';

.instruction-header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding:  0px 16px;
    width: 100%;

.main-logo {
    height: 85px;
    width: 120px;
  }

}  