@import "../../styles/global/style.scss";

.test-session-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  height: 100vh;
  box-sizing: border-box;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;

    .section-title {
      font-size: 24px;
      font-weight: 600;
      color: #333;
    }

    .timer {
      display: flex;
      gap: 8px;
      
      h2 {
        font-size: 20px;
        font-weight: 500;
        color: #666;
      }
    }
  }

  .question-content {
    width: calc(100vw - 40px);
    padding: 30px;
    margin: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;

    .question-text {
      font-size: 20px;
      font-weight: 500;
      color: #333;
    }

    .option-group,
    .option {
      font-size: 18px;
      color: #555;
    }
  }
}

  .loader {
    height: 60dvh;
  }