@import "../../../styles/global/style.scss";

.start-test-card {
  border: 1px solid rgb(208, 208, 208);
  border-radius: 8px;

  .card-header {
    padding: 20px;
    height: 70px;
    background-color: white;
    border-radius: 7px;
  }

  .card-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .term-box {
        display: flex;
        gap: 8px;

    .term-line {
        color: var(--text-color);
        cursor: pointer;
    }
    }

    .expiry-box {
        background-color: #cae6fb;
        padding: 12px;
        border-radius: 8px;

        .expiry-line {
            color: black;

            .date {
                color: #1f9eff;
                font-weight: 800;
            }
        }
    }

    .test-start-button {
        height: 40px;

        .start-button-text {
            font-weight: 800;
        }

        .disabled-color {
            color: gray;
        }
    }
  }
}
